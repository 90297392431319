<template>
  <div class="course-type-page">
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #333; cursor: default">课程分类</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="type-box">
      <div
        class="type-item"
        v-for="more in MoreType"
        :key="more.id"
        @click="$router.push(`/courlist?tid=${more.id}&tn=${more.type_name}`)"
      >
        <img :src="`http://tot.xlteacher.cn${more.pic}`" alt="" />
        <b>{{ more.type_name }}</b>
      </div>
      <el-pagination
        layout="prev,pager,next"
        :total="totalNumber"
        :page-size="pageSize"
        background
        prev-text="上一页"
        next-text="下一页"
        v-if="MoreType && totalNumber > 15"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalNumber: 0,
      pageSize: 1,
      MoreType: [],
      currentPage: 1,
    };
  },

  components: {},

  computed: {},
  mounted() {
    this.getMoreType();
  },
  methods: {
    // 获取更多课程分类
    getMoreType() {
      let fd = new FormData();
      fd.append("page_size", 15);
      this.axios.post("/index/more_course_type", fd).then((res) => {
        console.log(res);
        this.MoreType = res.data.list;
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);
      });
    },
    //更多课程分类-更改页码
    handleCurrentChange(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      this.axios.post("/index/more_course_type", fd).then((res) => {
        this.MoreType = res.data.list;
        this.totalNumber = res.data.count;
        this.pageSize = res.data.page_size;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.course-type-page {
  background-color: #f8f8f8;
  min-height: calc(100vh - 80px);
  overflow: hidden;
  .type-box {
    max-width: 1200px;
    height: 100%;
    background-color: #fff;
    margin: 0 auto;
    overflow: hidden;
    padding: 15px 10px;
    box-sizing: border-box;
    & > div.type-item {
      display: inline-block;
      width: 220px;
      height: 130px;
      margin-right: 8px;
      margin-left: 8px;
      margin-top: 7px;
      margin-bottom: 7px;
      text-align: center;
      position: relative;
      // background-color: #000;
      // // background-position-x: 50% !important;
      // background-position: center center !important;
      // background-size: 100% 100% !important;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:last-child {
        margin-right: 0;
      }
      b {
        position: absolute;
        font-size: 26px;
        color: #609bf6;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: default;
      }
    }
  }
}
</style>
<style lang="scss">
.course-type-page {
  .el-pagination {
    text-align: center;
    margin-top: 30px;
    .btn-prev,
    .btn-next {
      background-color: #fff !important;
      border: 1px solid #66a4ff;
      border-radius: 3px !important;
      padding: 5px 10px !important;
      box-sizing: border-box !important;
      &:hover {
        color: #66a4ff;
      }
      span {
        line-height: 1;
      }
    }
    .el-pager {
      li {
        font-weight: normal;
      }
    }
  }
}
</style>
